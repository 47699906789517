import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column } from 'components/atoms';

import LogoItems from 'components/molecules/LogoItems';

const Logos = ({ data, className }) => {
  const {
    css_id,
    caption,
    logos,
    stretch_logos_section,
    visibility: { hide_on_mobile },
  } = data;
  const { title } = caption;

  return (
    <Section
      className={cn(
        'logos-block',
        [className],
        hide_on_mobile && 'd-none d-lg-block'
      )}
      id={css_id || data.id}
    >
      <Container>
        <Row>
          <Column className="offset-lg-1" lg="9" col="12">
            <div
              className="logos-block__title"
              dangerouslySetInnerHTML={{ __html: title }}
            ></div>
          </Column>

          <LogoItems
            data={logos}
            stretch={stretch_logos_section}
            offset="0"
            col="12"
          />
        </Row>
      </Container>
    </Section>
  );
};

Logos.defaultProps = {
  className: '',
};

Logos.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default Logos;
